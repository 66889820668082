@import './variables';

/*
Styles for Forms
*/

.mat-form-field {
    font-size: 14px;
    width: 100%;
}

.mat-form-field-label-wrapper {
    position: initial !important;
}

.mat-form-field-label {
    // display: none !important;
    font-size: x-small !important;
    color: $color1;
    top: -20px;
    // text-align: right;
}

.mat-form-field-flex {
    height: 80px;
}

.mat-form-field-infix {
    margin-top: 24px;
    input,
    mat-select, 
    .mat-select {
        margin: 10px;
    }
}

.mat-slide-toggle {
    margin: 20px;
    font-size: large;
}

.mat-form-field-hint-wrapper {
    margin-top: -25px;
    z-index: 1;
    position: fixed;
    width: 100%;
}

mat-hint {
    color: $colorHelp;
    font-size: smaller;
    margin-top: 0px;
}

mat-card.item-edit {
    mat-form-field {
        width: 100%;
    }
    mat-slide-toggle {
        height: 25px;
        font-size: large;
        color: rgba(0, 0, 0, .87);
    }
    form {
        background-color: white;
        color: black;
        fieldset {
            // padding: 20px;
            // margin-bottom: 30px;
            color: black;
            &.form-info-fields {
                border: 1px solid #0C0033;
                background-color: floralwhite;
            }
            &.form-status-fields {
                &.EDITING {
                    border: 1px solid #856404;
                    background-color: #fff3cd;
                }
                &.VISIBLE {
                    border: 1px solid darkgreen;
                    background-color: lightgreen;
                }
                &.DELETED,
                &.BLOCKED {
                    border: 1px solid darkred;
                    background-color: lightcoral;
                }
            }
            legend {
                float: left;
                font-size: smaller;
                text-align: right;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(64, 140, 255, 0.54);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $color1;
}

.mat-button-toggle-checked {
    background-color: $color1;
    &.mat-button-toggle-appearance-standard {
        color: $color2;
    }
}
