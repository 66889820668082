@import './variables';
mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-today.cal-in-month.ng-star-inserted {
    color: $color1;
    background-color: $color2;
    span {
        font-weight: bolder;
        font-size: 1.2em;
    }
}

.mat-calendar-body-selected {
    background-color: $color1;
}

.cal-month-view .cal-day-badge {
    display: none;
}

@media screen and (max-width: 500px) {
    .cal-month-view .cal-header .cal-cell {
        font-size: x-small;
    }
    .cal-month-view .cal-day-number {
        font-size: 1em;
        margin-top: 3px;
        margin-right: 5px;
    }
    .cal-month-view .cal-day-cell {
        min-height: 50px;
    }
    .cal-month-view .cal-cell-top {
        min-height: 50px;
    }
    mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-today.cal-in-month.ng-star-inserted span {
        font-size: 1em;
    }
    .cal-month-view .cal-events {
        margin-top: -15px;
    }
    .cal-month-view .cal-event {
        width: 14px;
        height: 14px;
        // margin: 4px;
        // font-size: larger;
    }
    // .cal-month-view .cal-event::before {
    //     // content: "🍇​";
    // }
}

@media screen and (min-width: 501px) {
    .cal-month-view .cal-day-cell {
        min-height: 80px;
    }
    .cal-month-view .cal-cell-top {
        min-height: 80px;
    }
}