/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import 'base/variables';
@import 'base/cards';
@import 'base/calendar';
@import 'base/help';
@import 'base/items_list';
@import 'base/sections';
@import 'base/tables';
@import 'base/forms';
@import 'base/dashboard';
@import 'base/dialogs';
@import 'base/main_item_sections';
@import 'base/events';
* {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    // color: $color1;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0px;
}

body {
    height: 100%;
    mat-toolbar {
        a {
            color: $color2;
            border-bottom: 1px dotted $color2;
            text-decoration: none;
        }
        a:hover {
            color: $color2;
            border-bottom: 0px;
            text-decoration: none;
        }
    }
}

.preview {
    border: 1px solid darkgray;
    background-color: lightgray;
    padding: 20px;
    margin: 20px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(74, 74, 74, .8);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

// NAV
.buttons {
    text-align: right; // TODO should be buttons buttons-right
    &.buttons-center {
        display: block;
        text-align: center;
    }
    &.buttons-right {
        display: block;
        text-align: right;
    }
    &.buttons-left {
        display: block;
        text-align: left;
    }
}

.mat-tab-label-active {
    background-color: $color2;
    border: 2px solid $color1;
    border-radius: 25px;
}

@media screen and (max-width: $maxMobileWidth) {
    .share-button {
        width: 40px;
        .mat-icon {
            width: 40px;
            height: 40px;
        }
    }
    img.share-button {
        width: 40px !important;
        height: 40px !important;
    }
}

@media screen and (min-width: $minTabletWidth) and (max-width: $maxTabletWidth) {
    .share-button {
        margin-right: 8px;
        width: 45px;
        .mat-icon {
            width: 45px;
            height: 45px;
        }
    }
    img.share-button {
        margin-right: 8px;
        width: 45px !important;
        height: 45px !important;
    }
}

@media screen and (min-width: $minComputerWidth) {
    .share-button {
        margin-right: 12px;
        width: 50px;
        img,
        .mat-icon {
            width: 50px;
            height: 50px;
        }
    }
    img.share-button {
        margin-right: 12px;
        width: 50px !important;
        height: 50px !important;
    }
}

.share-button {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

.share-button {
    float: left;
    background-color: white;
}

td.mat-cell,
.mat-cell,
.buttons {
    margin-top: 5px;
    button.btn-1,
    button.btn-2,
    button.btn-extra,
    button.btn-admin,
    button.btn-alert {
        margin: 5px !important;
    }
    button.btn-1 {
        background-color: $color1;
        color: $color2;
    }
    button.btn-2 {
        background-color: $color2;
        color: $color1;
    }
    button.btn-extra {
        background-color: $colorExtra;
    }
    button.btn-admin {
        background-color: $colorAdmin;
    }
    button.btn-alert {
        color: $color1;
        background-color: $colorAlert;
    }
    button.btn-icon-1 {
        background-color: $color2;
        color: $color1;
        margin-left: 2px;
    }
}

button:not(.mat-button-disabled) {
    mat-icon.btn-1 {
        color: $color1;
    }
}

.section-list-options {
    display: flex;
    margin-bottom: 20px;
    .buttons {
        margin-left: 20px;
    }
}

.status {
    padding: 3px;
    &.status-VISIBLE {
        background-color: $colorSuccessBack;
        color: $colorSuccess;
        &::before {
            content: "✔️ ";
        }
    }
    &.status-EDITING {
        background-color: $colorWarningBack;
        color: $colorWarning;
        &::before {
            content: "⚠️ ";
        }
    }
    &.status-BLOCKED {
        background-color: $colorDangerBack;
        color: $colorDanger;
        &::before {
            content: "⛔ ";
        }
    }
    &.status-DELETED {
        background-color: $colorDeleteBack;
        color: $colorDelete;
        &::before {
            content: "🗑️ ";
        }
    }
}

// FIX: hide sroll
.mat-drawer-content {
    overflow: hidden !important;
}

@media screen and (max-width: 700px) {
    .label-for-tablet-desktop {
        display: none !important;
    }
    .mat-expansion-panel-body {
        padding: 4px !important;
    }
}

@media screen and (min-width: 701px) {
    .label-for-tablet-desktop {
        margin-left: 10px;
        color: $color1;
        font-weight: bolder;
    }
}

.mat-menu-content {
    width: 260px;
    overflow: hidden !important;
    background-color: $color2;
}

.mat-badge-content {
    background-color: $color1 !important;
    color: white !important;
}

.loading__container {
    display: block;
    margin: 48px;
    .loading__text {
        color: $color1;
        text-transform: uppercase;
        text-align: center;
        padding: 20px;
        font-size: larger;
        font-weight: bolder;
    }
    .loading__spinner {
        margin: auto;
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $color1;
}
