@import './variables';

/*
Styles for Sections
*/

body {
    div.section-wrapper {
        a,
        a:visited,
        a:active {
            color: $color1;
            border-bottom: 1px dotted $color2;
            text-decoration: none;
        }
        a:hover {
            color: $color1;
            background-color: $color2;
            border-bottom: 1px dotted $color1;
            text-decoration: dotted;
        }
        @media screen and (max-width: $maxTabletWidth) {
            margin: 10px 2px;
            padding: 0px 6px;
        }
        @media screen and (min-width: $minComputerWidth) {
            margin: 20px 4px;
            padding: 0px 20px;
        }
        .section-title {
            display: block;
            font-size: larger;
            line-height: 1.2em;
            color: $color2 !important;
            background-color: $color1;
            padding: 10px;
            margin-top: 0px;
            margin-bottom: 10px;
            // color: $color1;
            // background-color: $color2;
            @media screen and (max-width: $maxTabletWidth) {
                margin: 6px 0px 4px 0px;
            }
            @media screen and (min-width: $minComputerWidth) {
                margin: 20px 0px 10px 0px;
            }
        }
        .section-subtitle {
            display: block;
            font-size: medium;
            line-height: 1.1em;
            color: $color1 !important;
            background-color: $color2;
            padding: 6px;
            margin-bottom: 6px;
        }
        .section-main-info {
            color: $color1;
            width: 100%;
            margin: 1.2rem 0rem;
            font-size: larger;
            line-height: 1.5rem;
            text-align: center;
            .role {
                color: $color1;
                border-bottom: 1px dotted $color1;
                text-transform: capitalize;
                font-weight: bolder;
                font-size: medium;
            }
        }
        .section-text {
            display: block;
            font-size: medium;
            color: $color1;
            @media screen and (max-width: $maxTabletWidth) {
                margin: 6px 0px;
            }
            @media screen and (min-width: $minComputerWidth) {
                margin: 20px 0px;
            }
        }
    }
    .options-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .option-item {
            margin: 0px;
            padding: 10px 5px;
            flex-direction: column;
            .mat-card {
                background-color: $color2;
            }
            .mat-card-title {
                color: $color1;
            }
            .option-image {
                max-width: 200px;
                margin: auto;
            }
            @media screen and (max-width: $maxMobileWidth) {
                .mat-card {
                    padding: 4px;
                }
                .mat-card-title {
                    font-size: 0.8rem;
                    min-height: 30px;
                    line-height: 1rem;
                }
            }
            @media screen and (min-width: $minTabletWidth) and (max-width: $maxTabletWidth) {
                .mat-card {
                    padding: 8px;
                }
                .mat-card-title {
                    font-size: 1rem;
                    min-height: 38px;
                }
            }
            @media screen and (min-width: $minComputerWidth) {
                .mat-card {
                    padding: 12px;
                }
                .mat-card-title {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .mat-dialog-container,
    .mat-select-panel-wrap {
        border: 1px solid $color1;
        background-color: white;
        border-radius: 5px;
    }
}
