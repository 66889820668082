table {
    width: 100%;
    tr.mat-header-row {
        height: 16px;
        background-color: $color2;
    }
    .mat-sort-header-content {
        text-transform: uppercase;
        font-size: smaller !important;
    }
}

@media screen and (max-width: 700px) {
    td.td-date {
        width: 80px;
        max-width: 80px;
    }
    .td-extra,
    .td-extra-md,
    .td-extra-lg {
        display: none !important;
    }
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
    td.td-date {
        width: 120px;
        max-width: 120px;
    }
    .td-collapsed-info {
        width: 501px;
        max-width: 501px;
    }
    .td-extra-lg {
        display: none !important;
    }
    td.mat-column-types,
    td.mat-column-categories {
        text-align: right !important;
        padding-right: 12px !important;
    }
}

@media screen and (min-width: 1201px) {
    td.td-date {
        width: 120px;
        max-width: 120px;
    }
    .td-collapsed-info {
        width: 501px;
        max-width: 501px;
        display: none !important;
    }
    td.mat-column-types,
    .td-mat-column-categories {
        text-align: left !important;
        padding-right: 5px !important;
    }
}

.mat-column-baseId {
    color: gray;
    width: 20px;
}

.mat-column-id,
.mat-column-uid,
.mat-column-timestamp {
    color: gray;
    width: 100px;
    font-size: small;
    font-family: monospace;
    text-transform: uppercase;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.mat-column-timestampDetailed {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.mat-column-timestamp span {
    color: red;
    display: inline-block;
    font-size: smaller;
    border-bottom: 1px dotted red;
}

.mat-column-id,
.mat-column-uid {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mat-column-timestamp {
    line-height: 0.8rem;
}

.mat-column-photoURL,
.mat-column-image {
    text-align: center;
    width: 80px;
    border: 2px solid transparent;
    .img-thumbnail {
        max-width: 50px;
        width: 50px !important;
        max-height: 50px;
        height: 50px;
    }
}

td.mat-column-baseBigImage {
    width: 80px;
    .img-thumbnail {
        min-width: 64px;
        width: 64px !important;
        min-height: 64px;
        height: 64px;
    }
}

td.mat-column-displayName,
td.mat-column-baseBigName,
td.mat-column-name,
td.mat-column-desc {
    width: 400px;
    padding-left: 10px !important;
    padding-right: 5px !important;
}

.mat-column-event-entities {
    padding-left: 10px !important;
    vertical-align: top;
    text-align: left;
}

.mat-column-entities {
    text-align: center;
    width: 300px;
    &.LECTOR {
        color: red;
        span {
            text-transform: uppercase;
            font-size: smaller;
        }
    }
    &.ADMIN,
    &.SUPER {
        color: green;
        span {
            text-transform: uppercase;
            font-size: smaller;
        }
    }
    .img-thumbnail {
        min-width: 48px;
        width: 48px;
        min-height: 48px;
        height: 48px;
    }
}

.mat-column-baseSmallImage,
.mat-column-placeSmallImage,
.mat-column-sourceImage,
.mat-column-placeImage {
    text-align: center;
    width: 60px;
    padding-left: 0px !important;
    margin-right: 10px;
    .img-thumbnail {
        min-width: 48px;
        width: 48px;
        min-height: 48px;
        height: 48px;
    }
}

td.mat-column-baseTimestamp,
td.mat-column-baseSmallName,
td.mat-column-sourceName,
td.mat-column-placeName {
    width: 100px;
    font-size: smaller;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

td.mat-column-baseDescHorario {
    width: 160px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

td.mat-column-types,
td.mat-column-categories {
    text-align: left;
    width: 180px;
    font-size: large;
    line-height: 1.9rem;
    padding-left: 5px !important;
}

.mat-column-actions {
    width: 100px;
    text-align: center;
}

.mat-column-actions2 {
    width: 140px;
    text-align: center;
}

.mat-column-actions3 {
    min-width: 150px;
    text-align: center;
}

.mat-column-actions4 {
    min-width: 200px;
    text-align: center;
}

.mat-column-baseActions5 {
    width: 200px;
    text-align: left;
    .mat-icon-button {
        width: 30px;
        height: 30px;
    }
}

td.mat-column-roleDefault,
td.mat-column-auditType,
td.mat-column-role,
td.mat-column-status {
    width: 110px;
    font-size: smaller;
    text-transform: uppercase;
    text-align: center;
    color: black;
    background-color: transparent;
    &.SUPER,
    &.DELETED {
        div.inside {
            color: lightgrey !important;
            border: 1px solid black;
            background-color: #202020;
            font-size: smaller;
        }
    }
    &.LOGOUT,
    &.ADMIN,
    &.BLOCKED {
        div.inside {
            border: 1px solid darkred;
            background-color: lightcoral;
            font-size: smaller;
        }
    }
    &.AUTOR {
        div.inside {
            border: 1px solid orangered;
            background-color: orange;
            font-size: smaller;
        }
    }
    &.LECTOR,
    &.EDITING {
        div.inside {
            border: 1px solid #856404;
            background-color: #fff3cd;
            font-size: smaller;
        }
    }
    &.LOGIN_EMAIL,
    &.LOGIN_PROVIDER,
    &.VISIBLE {
        div.inside {
            border: 1px solid darkgreen;
            background-color: lightgreen;
            font-size: smaller;
        }
    }
    div.inside {
        border: 1px solid black;
        background-color: lightgray;
        font-size: smaller;
    }
}

td.mat-column-auditCreation,
td.mat-column-collapsed-info {
    div.full-inside {
        height: 14px;
        float: right;
        margin: 3px;
    }
    div.inside {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        float: right;
        margin: 3px;
    }
    &.DELETED {
        div.inside {
            color: lightgrey !important;
            border: 1px solid black;
            background-color: #202020;
        }
    }
    &.SUPER {
        div.inside {
            border: 1px solid darkslateblue;
            background-color: lightsteelblue;
        }
    }
    &.ADMIN,
    &.BLOCKED {
        div.inside {
            border: 1px solid darkred;
            background-color: lightcoral;
        }
    }
    &.AUTOR {
        div.inside {
            border: 1px solid orangered;
            background-color: orange;
        }
    }
    &.LECTOR,
    &.EDITING {
        div.inside {
            border: 1px solid #856404;
            background-color: #fff3cd;
        }
    }
    &.VISIBLE {
        div.inside {
            border: 1px solid darkgreen;
            background-color: lightgreen;
        }
    }
}

.marked-row {
    background-color: $colorAlert;
    border: 1px solid $colorAlertBorder;
    .btn-icon-1 {
        color: $colorAlert;
    }
}

.button-collapsed {
    float: left;
    margin: 10px !important;
}

.make-deleted {
    background-color: #000000;
    * {
        color: lightgray !important;
    }
}

.col-list-container {
    margin-bottom: 2px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .col-list-item {
        display: flex;
        flex-flow: row wrap;
        .item-image {
            width: 40px;
            height: 40px;
            float: left;
            margin-right: 10px;
        }
        .item-texts {
            display: flex;
            flex-flow: column wrap;
            .item-name {
                font-size: smaller;
                font-weight: bolder;
                text-transform: uppercase;
                color: black;
            }
            .item-desc {
                font-size: smaller;
                color: #808080;
            }
        }
        .item-button {
            order: -1;
        }
    }
}