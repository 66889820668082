@import './variables';


.dialog__header {
    span.mat-dialog-title {
        font-size: larger;
        line-height: 1.2em;
        color: $color2;
        background-color: $color1;
        padding: 10px;
    }
}

.dialog__content {
    #dialog__main-image-wrapper {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin: 10px;
        img.main-image {
            padding: 3px;
            @media screen and (max-width: $maxMobileWidth) {
                width: 180px;
                border: 3px solid $colorExtra;
                border-radius: 1em;
            }
            @media screen and (min-width: $minTabletWidth) and (max-width: $maxTabletWidth) {
                width: 240px;
                border: 6px solid $colorExtra;
                border-radius: 1em;
            }
            @media screen and (min-width: $minComputerWidth) {
                width: 300px;
                border: 9px solid $colorExtra;
                border-radius: 1em;
            }
        }
    }
    #dialog__several-images-wrapper {
        .images-container-text {
            span {
                color: darkgray;
                text-transform: uppercase;
                font-size: smaller;
            }
        }
        .images-container {
            border: 1px dotted lightgray;
            display: flex;
            flex-direction: row;
            justify-content: center;
            img {
                margin: 10px;
                border: 1px solid darkgray;
                width: 100px;
                height: 100px; // TODO center, no resize
            }
            img.selected-image {
                padding: 3px;
                border: 3px solid $colorExtra !important;
                border-radius: 1em;
            }
        }
    }
    #dialog__event-id-wrapper {
        padding-right: 30px;
        .event-mapped-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }
    #dialog__images-wrapper {
        text-align: center;
        .images-container {
            border: 0px solid lightgray;
            margin: -10px;
        }
        .dialog__image {
            width: 200px;
            margin-bottom: 20px;
            border: 0px dotted lightgray;
            padding: 4px;
        }
    }
    .dialog__section-type-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: stretch;
        padding: 4px 0px;
        margin: 0px 0px 20px 0px;
        .dialog__item-type {
            padding-top: 4px;
            flex: 1 1 0;
            width: 0;
            .icon {
                font-size: xx-large;
            }
            .explication {
                font-size: smaller;
                line-height: 1.2em;
                text-transform: uppercase;
                padding-top: 10px;
                font-weight: bolder;
            }
        }
    }
    .dialog__section-wrapper {
        border: 1px dashed lightgray;
        padding: 10px;
        border-radius: 25px;
        .base-container {
            margin-bottom: 10px;
            display: flex;
            align-items: stretch;
            flex-wrap: nowrap;
            justify-content: space-between;
            .mat-form-field.event-id-field {
                width: 300px;
            }
            .mat-form-field.name-field {
                width: 100%;
            }
            .mat-form-field.description-field {
                width: 100%;
                margin: -30px 0px;
            }
            .mat-form-field.date-field {
                width: 200px;
            }
            .mat-form-field.time-field {
                margin-left: 10px;
                text-align: center;
                .mat-slide-toggle {
                    margin-top: -30px;
                    margin-left: 0px;
                }
            }
        }
    }
    .dialog__section-wrapper+.dialog__section-wrapper {
        margin-top: 8px;
    }
    .mat-button-toggle-checked {
        border: 2px solid $color1 !important;
        border-radius: 5px !important;
        background-color: $color2 !important;
    }
    .button-toggle-container {
        margin-bottom: 30px;
        .mat-button-toggle-group {
            flex-wrap: wrap;
            display: grid;
            .mat-button-toggle.button__link-type {
                white-space: normal;
            }
        }
    }
}

.backdrop-dialog {
    background-color: rgba(0, 0, 0, 0.8);
}

.mat-dialog-title {
    text-align: center;
}

mat-dialog-container {
    mat-form-field {
        width: 100%;
    }
}

.mat-dialog-actions {
    background-color: $color2;
    border: $color1;
}

.option-img {
    vertical-align: middle;
    margin-right: 8px;
}

[dir='rtl'] .option-img {
    margin-right: 0;
    margin-left: 8px;
}