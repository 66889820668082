@import './variables';
.items__container {
    div {
        margin-bottom: 0px !important;
    }
    .item--title {
        color: $color1;
        font-size: small;
        text-transform: uppercase;
        font-weight: bold;
        margin: 6px !important;
    }
    .item--marked {
        background-color: $color2 !important;
        border: 5px solid $color1;
    }
    .item--disabled {
        display: none;
    }
    .item--selected,
    .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.1);
        padding: 5px;
        margin-bottom: 10px;
        background-color: white;
        .item-image {
            img.circle {
                border-radius: 50%;
                max-width: 40px; // 100px for big devices
                max-height: 40px; // 100px for big devices
            }
            img.square {
                order: 1;
                width: 54px;
                height: 54px;
            }
        }
        .item-body {
            padding-left: 10px;
            width: 100%;
            .item-heading {
                @media screen and (max-width: 450px) {
                    font-size: small;
                }
                @media screen and (min-width: 451px) and (max-width: 600px) {
                    font-size: medium;
                }
                @media screen and (min-width: 601px) {
                    font-size: larger;
                }
            }
            .item-heading-audit,
            .item-title-audit {
                font-size: medium;
            }
            .item-timestamp,
            .item-data,
            .item-text {
                @media screen and (max-width: 450px) {
                    font-size: smaller;
                }
                @media screen and (min-width: 451px) and (max-width: 600px) {
                    font-size: smaller;
                }
                @media screen and (min-width: 601px) {
                    font-size: smaller;
                }
            }
            .item-timestamp {
                color: $color1;
            }
            .item-heading-audit {
                color: $color1;
                background-color: $color2;
                font-weight: bolder;
                border-radius: 10px;
                padding-left: 10px;
            }
            .item-heading {
                font-weight: bolder;
                text-transform: uppercase;
            }
            .item-title-audit {
                margin: 6px 4px;
                color: $color1;
                text-transform: uppercase;
                font-weight: bold;
                cursor: pointer;
            }
            .item-data {
                margin: 2px 4px;
            }
            .item-link {
                text-align: right;
                padding-top: 6px;
                span.text {
                    color: $color1;
                    background-color: $color2;
                    border-bottom: dotted 1px $color1;
                }
            }
            .item-description {
                margin: 8px 40px;
            }
            .item-text {
                padding-top: 10px;
                margin-bottom: 0px;
            }
            .item-button-delete {
                float: right;
                margin-top: 10px;
                margin-right: -12px;
                color: $color1;
            }
            .item-admin-buttons {
                width: 100%;
                text-align: center;
                padding-bottom: 10px;
            }
        }
        .item-fav-button {
            display: inline;
            float: right;
            button {
                @media screen and (max-width: 450px) {
                    min-width: 28px;
                    min-height: 28px;
                }
                @media screen and (min-width: 451px) and (max-width: 600px) {
                    min-width: 32px;
                    min-height: 32px;
                }
                @media screen and (min-width: 601px) {
                    min-width: 36px;
                    min-height: 36px;
                }
                &.button-image {
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    margin: 0px 8px;
                }
            }
        }
    }
    .item--selected {
        background-color: $color2 !important;
        border: 5px solid $color1;
        .item-heading,
        .item-name {
            font-size: larger !important;
        }
    }
    .icon {
        width: 32px;
        display: inline-block;
        text-align: center;
        // background-color: red;
    }
}