$color1: #003A59;
$color2: #DEEEEC;
$colorHelp: rgba(64, 140, 255, 0.54);
$colorExtra: #3E8B82;
$colorExtra2: #ff4081;
$colorAdmin: #ff4081;
$colorAlert: gold;
$colorAlertBack: lightgoldenrodyellow;
$colorAlertBorder: darkgoldenrod;
// Colors visible-editing-blocked-deleted
$colorSuccess: darkgreen;
$colorSuccessBack: lightgreen;
$colorWarning: #856404;
$colorWarningBack: #fff3cd;
$colorDanger: darkred;
$colorDangerBack: lightcoral;
$colorDelete:black;
$colorDeleteBack: #505050;
// Tables and lists
$colorRowEnabledBg: white;
$colorRowDisabledBg: lightgray;
$colorRowDisabledData: darkgray;
$colorTitleData: black; // Data, titles or info
$colorExtraData: #333333;
// Items: Events, Notices & Links
$colorEventFront: #003A59;
$colorEventBack: rgb(#003A59, 0.5);
$colorNoticeFront: gold;
$colorNoticeBack: rgb(gold, 0.5);
$colorLinkFront: #3E8B82;
$colorLinkBack: rgb(#3E8B82, 0.5);
// Margin/Padding
$spacer: 1rem;
// Breakpoints
// TODO Change https://getbootstrap.com/docs/5.0/layout/breakpoints/#media-queries
$maxMobileWidth: 500px;
$minTabletWidth: 501px;
$maxTabletWidth: 700px;
$minComputerWidth: 701px;
$maxComputerWidth: 992px;
$minLargeComputerWidth: 993px;