.card-detail {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    input {
        color: black !important;
    }
}

.card-detail-block {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    display: block;
    img.img-thumbnail {
        width: 48px;
        height: 48px;
        float: left;
        margin-right: 10px;
    }
    span.desc {
        color: #333333;
        font-size: smaller;
        text-transform: uppercase;
    }
    span.name {
        color: black;
        font-size: larger;
        font-weight: bolder;
    }
}

.card-chip-list mat-chip-list div {
    margin-top: 0px;
    span {
        font-size: smaller;
    }
}

.mat-tab-body-content {
    overflow: hidden !important;
}

mat-card-content.item-view {
    div {
        margin-bottom: 30px;
        img {
            width: 64px;
            height: 64px;
            float: left;
            margin-right: 10px;
        }
    }
}

.section-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    .item-card {
        display: table-cell;
        @media screen and (max-width: $maxMobileWidth) {
            width: 100%;
        }
        @media screen and (min-width: $minTabletWidth) and (max-width: $maxTabletWidth) {
            width: 48%;
        }
        @media screen and (min-width: $minComputerWidth) and (max-width: $maxComputerWidth) {
            width: 32%;
        }
        @media screen and (min-width: $minLargeComputerWidth) {
            width: 260px;
        }
    }
}

mat-card.item-card {
    margin-bottom: 10px;
    &.DELETED {
        color: lightgrey !important;
        border: 5px solid black;
        background-color: #606060;
    }
    &.BLOCKED {
        border: 5px solid darkred;
        background-color: lightcoral;
    }
    &.EDITING {
        border: 5px solid #856404;
        background-color: #fff3cd;
    }
    .item-status {
        text-transform: uppercase;
        font-size: small;
        padding: 0px 5px;
        border-radius: 10px;
        &.DELETED {
            color: lightgrey !important;
            border: 1px solid black;
            background-color: #202020;
        }
        &.BLOCKED {
            border: 1px solid darkred;
            background-color: lightcoral;
        }
        &.EDITING {
            border: 1px solid #856404;
            background-color: #fff3cd;
        }
        &.VISIBLE {
            border: 1px solid darkgreen;
            background-color: lightgreen;
        }
    }
    .item-content {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 3px;
        .item-timestamp {
            text-transform: uppercase;
            font-size: x-small;
            padding: 0px 5px;
            background-color: $color1;
            border: 1px solid $color1;
            border-radius: 10px;
            color: $color2;
            float: right;
        }
    }
}